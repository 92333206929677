@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

p {
  color: #023047;  
  font-size: 15px;
  margin-bottom: 0px !important;
}

mjx-container svg{
  display: inline;
}

mjx-container[jax="SVG"][display="true"] {
    margin: 0.5rem 0 !important;
}

.markdown-body {
  font-size: 14px;
}

.markdown-body.medium {
  font-size: 14px;
  background-color: unset;
}

.modal-light .ant-modal-content {
  background-color: #F9FCFE;
}

.ant-menu-item-selected .icon {
  color:#fb5607
}

.ant-menu-item-selected .label {
  color:#fb5607;
  font-weight: 600;
}

.ant-menu-item {
  padding-inline: 28px 16px !important;
  display: flex !important;
  align-items: center;
}

.ant-card {
  margin-bottom: 16px;
}